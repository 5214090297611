body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-bar {
  height: 70px;
  line-height: 70px;
}

#language-selector {
  display: flex
}

#naslov {
  margin-left:16px;
  line-height: normal;
}

#icon-buttons {
  display: flex;
}

#header-controls {
  margin-left: auto;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: right;
}

#header-container {
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
  align-items: center;
}

.omeji-zapise {
  width: 290px;
  display: flex;
}

.map-controls {
  position: fixed;
  bottom: 70px;
  right: 30px;
  display: flex;
  flex-direction: column;
}

.drawer-list-item {
  height: 100px;
}

.drawer-header {
  margin-top: 60px;
  margin-bottom: -10px;
}

.drawer-header button {
  margin-top:10px;
}

.popup-div {
  display: flex;
}

.json-to-table table {
  font-size: 0.7rem!important;
}

.image_wrapper {
  position: relative; 
}

#logo {
  margin-top: 70px;
  width: 97%;
  cursor: pointer; 
}

.image_overlay {
  position: absolute;
  color:beige; 
  background: rgba(57, 57, 57, 0.5);

  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;

  left: 0;
  bottom: 0;
  width: 100%;
}

.popup-feature-props {
  width:310px;
  font-size: small;
}

#icons-mobile {
  margin-left:5px;
  margin-top:4px;
  display: none;
}

@media screen and (max-width: 1280px) { 

  #naslov { font-size:18px; }
  .omeji-zapise {
    width:calc(100vw - 900px);
  } 
}

@media screen and (max-width: 1080px) { 
  
  #header-container {
    margin-top:8px;
    margin-right: 5px;
  }

  #icon-buttons {
    display: none;
  }
  
  #icons-mobile {
    display: block;
  }

  .omeji-zapise {
    width:calc(100vw - 540px);
  } 
}

@media screen and (max-width: 790px) { 
  .popup-div {
    display: flex;
    flex-direction: column;
    max-width: 312px;
  }

  .popup-div div{
    margin-bottom:10px;
  }
}

@media screen and (max-width: 740px) {
  .app-bar {
    height:80px;
  }

  #logo {
    margin-top: 80px;
  }

  #naslov {
    position: absolute;
    left:10px;
    top:7px;
    font-size: 14px;
    width:100vw;
    text-align: center;
  }

  #header-container {
    margin-top: 25px;
    margin-left:15px;
  }

  .omeji-zapise {
    width:calc(55vw - 15px);
  }

  #language-selector {
    width: calc(25vw - 15px);
  }
} 

@media screen and (max-width: 520px) { 
  .popup-div {
    max-width: 222px;
  }

  .popup-feature-props {
    width:220px;
  }
}

@media screen and (max-height: 500px) {
  .map-controls {
    flex-direction: row;
    bottom: 56px;
    right:10px;
  }
  .map-controls div{
    margin-left:10px;
  }
  .leaflet-control-minimap {
    right:5px;
    top:80px;
  }

  .leaflet-geosearch-bar {
    right: 90px;
    bottom: 108px;
  }

  .leaflet-control-layers {
    right: 90px;
    bottom: 60px;
  }
}

@media screen and (max-height: 380px) { 
  .map-controls {
    bottom: 46px;
  }

  .leaflet-control-minimap {
    top:60px;
  }

  #header-controls {
    zoom:0.95;
  }

  #map{
    top: 50px;
    height: calc(100vh - 50px)!important;
  }

  .leaflet-geosearch-bar {
    right: 115px;
    position: sticky!important;
  }

  .leaflet-control-layers {
    right: 90px;
    bottom: 50px;
  }
}

.menu-item-label {
  padding-right: 2px;
}

.ranljive-skupine-popup {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 10px;
  justify-content: right;
}

.ranljive-skupine-popup img{
  height:20px;
}

.disabled {
  pointer-events: none;
  color: gray
}

.disabled img, .disabled svg, .disabled a {
  filter: grayscale(100%); /* Shade the image */
}

.disabled a {
  text-decoration: none;
}

.image_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.small_image_wrapper {
  width: 310px;
  height: 200px;
}

.large_image_wrapper {
  height: 80vh;
}

.image_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}






